<template>
  <div>
    <StringboxFarmCompariton :stringdata="stringdata" />
  </div>
</template>

<script>
import StringboxFarmCompariton from "@/views/components/Stringbox/StringboxFarmCompariton.vue";

export default {
  components: {
    StringboxFarmCompariton,
  },
  data() {
    return {
      stringdata: {},
    };
  },
  computed: {},

  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);
    const windowsWidth = document.documentElement.clientWidth;

    const self = this;
    let k = 0;
    function myTimer1() {
      if (k > 30) {
        clearInterval(myVar1);
      }

      k += 1;
      const { stringboxMeasureList, santralDataList, santralStringboxData } =
        self.$store.getters;
      if (
        Object.keys(stringboxMeasureList).length > 0 &&
        Object.keys(santralDataList).length > 0 &&
        Object.keys(santralStringboxData).length > 0
      ) {
        clearInterval(myVar1);
        self.stringdata = {
          measures: stringboxMeasureList,
          santralList: santralDataList,
          santralStringboxData,
          windowsWidth,
        };
      }
    }
  },
  methods: {},
};
</script>